<template>
  <div id="app">
    <app-header />
    <router-view id="content" />
    <app-footer />
  </div>
</template>
<script>
import { getUserLanguage } from "./lib/lang.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "app",
  components: {
    AppHeader: Header,
    AppFooter: Footer
  },
  created() {
    this.$i18n.locale = getUserLanguage();
  },
  methods: {
    afterLeave() {
      this.$root.$emit("triggerScroll");
    }
  }
};
</script>
<style lang="scss">
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media (min-width: 320px) {
  .wrapper {
    margin: 0 auto;
    width: 320px;
    padding-left: 30px;
    padding-right: 30px;
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    grid-column-gap: 22px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    margin: 0 auto;
    width: 768px;
    padding-left: 48px;
    padding-right: 48px;
    display: grid;
    grid-template-columns: repeat(8, [col-start] 1fr);
    grid-column-gap: 32px;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    margin: 0 auto;
    width: 1024px;
    padding-left: 56px;
    padding-right: 56px;
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-column-gap: 28px;
  }
}

@media (min-width: 1440px) {
  .wrapper {
    margin: 0 auto;
    width: 1440px;
    padding-left: 150px;
    padding-right: 150px;
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-column-gap: 36px;
  }
}
</style>
