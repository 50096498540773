import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import { scrollTo } from "vue-scrollto";
import messages from "./locales/messages";
Vue.use(Router);

const scrollBehavior = function(to, from, savedPosition) {
  if (from.name == to.name && to.hash !== "") {
    const options = {
      container: "body",
      easing: "ease-in",
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
    scrollTo(to.hash, 400, options);
  }
  if (savedPosition) {
    return savedPosition;
  }
  const position = {};
  if (!to.hash) {
    position.x = 0;
    position.y = 0;
  } else {
    position.selector = to.hash;
  }
  return position;
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { scrollToTop: false }
    },
    {
      path: "/fr",
      name: "home",
      component: Home,
      meta: { scrollToTop: false, lang: "fr" },
      props: { lang: "fr" }
    },
    {
      path: "/en",
      name: "home",
      component: Home,
      meta: { scrollToTop: false, lang: "en" },
      props: { lang: "en" }
    },
    {
      path: "/en/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
      meta: { scrollToTop: true, lang: "en" },
      props: { lang: "en" }
    },
    {
      path: "/fr/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
      meta: { scrollToTop: true, lang: "fr" },
      props: { lang: "fr" }
    }
  ]
});
router.beforeEach((to, from, next) => {
  const lang = to.meta.lang || "en";
  document.documentElement.setAttribute("lang", lang);
  const headMessages = messages[lang].message.head;
  document.title = headMessages.title;
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    el => el.parentNode.removeChild(el)
  );

  const descriptionTag = document.createElement("meta");
  descriptionTag.setAttribute("name", "description");
  descriptionTag.setAttribute("content", headMessages.metaDescription);
  descriptionTag.setAttribute("data-vue-router-controlled", "");
  document.head.appendChild(descriptionTag);

  // twitter and facebook tags, cf https://css-tricks.com/essential-meta-tags-social-media/
  createMetaTag("og:title", headMessages.title);
  createMetaTag("og:description", headMessages.metaDescription);
  createMetaTag("og:image", "https://geometric.anagraph.io/logo-social.png");
  createMetaTag("og:url", "https://geometric.anagraph.io");
  createMetaTag("twitter:card", "summary_large_image");
  createMetaTag("type", "website");

  const alternate = document.createElement("link");
  alternate.setAttribute("rel", "alternate");
  alternate.setAttribute("hreflang", headMessages.linkAlternate.hreflang);
  alternate.setAttribute(
    "href",
    `${headMessages.linkAlternate.href}/${to.name}`
  );
  alternate.setAttribute("data-vue-router-controlled", "");
  document.head.appendChild(alternate);
  next();
});

function createMetaTag(name, content) {
  const tag = document.createElement("meta");
  tag.setAttribute("name", name);
  tag.setAttribute("property", name);
  tag.setAttribute("content", content);
  tag.setAttribute("data-vue-router-controlled", "");
  document.head.appendChild(tag);
}

export default router;
