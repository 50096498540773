<template>
  <footer id="footer" class="background-dark">
    <div class="wrapper">
      <div id="left-footer">
        Geometric {{ $t("message.footer.by") }}
        <a class="link" href="http://anagraph.io">Anagraph</a>
      </div>
      <div id="center-footer">© Geometric 2018</div>
      <div id="right-footer"><locale-changer /></div>
    </div>
  </footer>
</template>

<script>
import LocaleChanger from "./LocaleChanger.vue";
export default {
  name: "v-footer",
  components: {
    LocaleChanger
  }
};
</script>

<style lang="scss">
#footer {
  color: #c2c2c8;
  letter-spacing: 0.1px;
  font-family: "geomanist-book";
  padding-bottom: 89px;
  #left-footer {
    grid-column: col-start 1 / span 4;
    text-align: left;
    font-size: 0.875rem;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  #center-footer {
    grid-column: col-start 5 / span 4;
    text-align: center;
    font-size: 12px;
    opacity: 0.5;
    text-align: center;
  }
  #right-footer {
    grid-column: col-start 9 / span 4;
    text-align: right;
    font-size: 0.875rem;
  }
}
</style>
