const messages = {
  fr: {
    message: {
      head: {
        title: "geometric, repérez vos nouvelles opportunités",
        metaDescription:
          "L'intelligence géographique au service des décisionnaires d'affaires. Découvrez de nouveaux insights et donnez vie à vos données.",
        linkAlternate: {
          href: "https://geometric.anagraph.io/en",
          hreflang: "en"
        }
      },
      header: {
        solutions: "Solutions",
        pricing: "Prix",
        about: "À Propos",
        explore: "Explorer",
        languageSwitch: {
          name: "English",
          path: "en"
        }
      },
      intro: {
        head_1: "Repérez vos nouvelles opportunités",
        text_1: `L'intelligence géographique au service des décisionnaires d'affaires.<br>
        Découvrez de nouveaux insights et donnez vie à vos données.`,
        cta_1: "Explorez Geometric",
        head_2: "Prêt à repérer de nouvelles opportunités?",
        text_2: `
        Geometric est un outil basé sur l’intelligence géographique qui, 
        avec une facilité déconcertante et à moindre coût, 
        permet aux entreprises de se connecter à leur audience. 
        Et ce, en temps réel grâce au croisement de données : 
        les vôtres et celles que nous générons pour vous. <br>
        Nous vous guidons dans la compréhension du profil 
        et des comportements de votre cible tout en repérant des schémas et des tendances. <br>
        <br>
        Nous permettons ainsi aux professionnels du marketing et des ventes de repérer leur prochaine opportunité 
        et de ne jamais rater leur cible.
        `,
        cta_2: "Explorez Geometric"
      },
      goals: {
        head_1: "Une audience maîtrisée au pied carré.",
        text_1: "Approfondissez vos personas avec des données géolocalisées",
        head_2: "Des campagnes qui visent juste.",
        text_2:
          "Parce qu'à une rue près vous pouvez manquer votre cible, intégrez dès aujourd'hui notre outil pour des campagnes ultraciblées.",
        head_3: "Des stratégies toujours plus précises.",
        text_3: `
        Faites confiance à un outil puissant pour briser les silos
         et favoriser la collaboration sur un terrain d’entente : 
         des insights basés sur la localisation pour des décisions plus précises.`
      },
      pricing: {
        title: 'Une offre sur mesure, pour vous',
        description:
          'Choisissez parmi quatres forfaits pour répondre à vos besoins',
        limit: {
          youaregoing: `vous allez dépasser votre limite de crédits.<br> 
          Choisissez un nouveau plan`,
          or: 'ou',
          continue: 'continuez avec votre plan actuel',
          andImport: ' et importer les %{credits} crédits restant'
        },
        public: {
          category: 'Public',
          title: '0$',
          description: `
          <div> - Accédez aux données démographiques </div>
          <div> - Localisez 1000 clients </div>
          `,
          button: 'Essai gratuit'
        },
        basic: {
          category: 'Basic',
          title: '19.99$ / mois',
          description: `
          <div> - Accédez aux données démographiques </div>
          <div> - Localisez 5000 clients </div>
          <div> - Identifiez vos personas </div>
          `,
          button: 'Découvrir'
        },
        advanced: {
          category: 'Avancé',
          title: '49.99$  / mois',
          description: `
          <div> - Accédez aux données démographiques </div>
          <div> - Localisez 25000 clients </div>
          <div> - Identifiez vos personas </div>
          <div> - Ciblez votre nouvelle audience </div>
          `,
          button: 'Découvrir'
        },
        custom: {
          category: 'Personnalisé',
          title: 'Custom',
          description: `
          Contactez-nous 
          pour localisez plus de 25 000 clients
          ou identifier vos personas et ciblez votre audience
          sur des caractéristiques personnalisées
          `,
          button: `<a href="mailto:nicolas@anagraph.io?Subject=Hello%20Anagraph">
          Contact Us
          </a>`
        }
      },
      contact: {
        title: "gardons contact",
        text: `
        Écrivez-nous à <a class="link" href="mailto:nicolas@anagraph.io?Subject=Hello%20Anagraph">nicolas@anagraph.io</a> 
        ou Inscrivez-vous à notre infolettre`,
        email_placeholder: "entrez votre adresse courriel"
      },
      about: {
        head_1:
          "L'intelligence géographique au service des décisionnaires d'affaires.",
        text_1: `
        Petite définition de l'intelligence géographique :
        L'intelligence géographique est une méthode innovante, à la croisée du BI (Business Intelligence) et des SIG (Système d'informations Géographique). Plus que de visualiser les informations sur une carte, l'IG permet d'utiliser les données qui ont un référentiel géographique pour en faire ressortir des résultats exploitables dans le milieu des affaires grâce à la mise en évidence de relations de voisinage, invisibles dans des tableurs Excel.
        `,
        head_2: "Geometric, une solution signée Anagraph.",
        text_2: `
        Aujourd'hui il existe des centaines de logiciels et de solutions  pour trouver et récolter des données. 
        C'est en constatant que peu de décisionnaires étaient en mesure de les visualiser et de leur donner du sens que nous avons développé Geometric. Un outil qui s'insrit dans l'ADN d'Anagraph à savoir la facilitation de prises de décision grâce à un repère universel et intinctif : la carte.
        Des cartes intelligentes pour éclairer les leaders et soutenir l'innovation au coeur des petites comme des grandes entreprises canadiennes.
        `,
        head_3: "Anagraph, studio d'innovation québécois",
        text_3: `
        Basé dans les Laurentides, notre joyeuse entreprise est composée de cerveaux créatifs prêts à imaginer l'avenir de la visualisation de l'information géospatiale.
        . Avec à sa tête Nicolas, fondateur possédant une expérience éprouvée en technologie de l'information spatiale et services de géolocalisation, aujourd'hui président de l'Association Canadienne des Sciences Géomatiques.

        . Et à ses côtés, Jean-Denis, Thomas, Marc-André, Mathew, Clément et Alexis, développeurs et programmeurs émérites spécialisés dans la création de solutions intuitives.
        `,
        head_4:
          "Le champs des possibles en matière d'intelligence géographique",
        text_4: `
        Nos récentes collaborations avec des acteurs du secteur publique comme privé démontrent la puissance de l'intelligence géographique appliquée à des champs divers et variés. <br>
        - Si vous êtes curieux de découvrir <a class="link" href="http://www.anagraph.io">nos projets en cours et leur portée.</a><br>
        - Si vous pensez que nous pouvons <a class="link" href="#contact">vous aider à réaliser l'impossible.</a>
        `
      },
      footer: {
        by: "par"
      }
    }
  },
  en: {
    message: {
      head: {
        title: "geometric, Map out your company's next move",
        metaDescription: `Location Intelligence for the next gen of marketers.
        Give yourself some powerful insights and bring your data to life.`,
        linkAlternate: {
          href: "https://geometric.anagraph.io/fr",
          hreflang: "fr"
        }
      },
      header: {
        solutions: "Solutions",
        pricing: "Pricing",
        about: "About",
        explore: "Explore",
        languageSwitch: {
          name: "Français",
          path: "fr"
        }
      },
      intro: {
        head_1: "Map out your company's next move",
        text_1: `Location Intelligence for the next gen of marketers <br>
        Give yourself some powerful insights and bring your data to life.`,
        cta_1: "explore Geometric",
        head_2: "Get to know the market from a new perspective!",
        text_2: `
        Geometric is a location-intelligence tool 
        that effortlessly – and affordably - connects companies to their consumers. 
        In real time. 
        And, through hyper-precise data, visually guides them through their behaviors, patterns, and consumptions. 
        This allows marketing and sale professionals, to map out their next super smart business move and to succeed their campaigns. 
        It’s like market research without the market research part.
        `,
        cta_2: "Explore Geometric"
      },
      goals: {
        head_1: "Let your consumers’ behavior be your edge",
        text_1: "",
        head_2: "Stay ahead of the market while learning about it",
        text_2: "",
        head_3: "Inject intelligence into location, location, location",
        text_3: ""
      },
      pricing: {
        title: 'A little goes a long way',
        description:
          'Choose from 4 simple pricing plans to best serve your company.',
        limit: {
          youaregoing: `you are going to go over your credit limit. <br> 
        Upgrade to a new plan`,
          or: 'or',
          continue: 'continue with the current plan',
          andImport: 'and import the %{credits} remaining credits'
        },
        public: {
          category: 'Public',
          title: '0$',
          description: `
          <div> - Access to demographic layers </div>
          <div> - Locate 1000 customers`,
          button: 'Try for free'
        },
        basic: {
          category: 'Basic',
          title: '19.99$ / month',
          description: `
          <div> - Access to demographic layers </div>
          <div> - Locate 5000 customers </div>
          <div> - Discover your persona </div>
          `,
          button: 'Discover'
        },
        advanced: {
          category: 'Advanced',
          title: '49.99$  / month',
          description: `
          <div> - Access to demographic layers </div>
          <div> - Locate 25000 customers </div>
          <div> - Discover your personas </div>
          <div> - Target your new audience </div>
          `,
          button: 'Discover'
        },
        custom: {
          category: 'Custom',
          title: 'Custom',
          description: `
          Contact us 
          to locate more than 25,000 customers 
          or create your personas and target your audience
          based on custom features
          `,
          button: `<a href="mailto:nicolas@anagraph.io?Subject=Hello%20Anagraph">
          Contact Us
          </a>`
        }
      },
      contact: {
        title: "we'd love to talk",
        text: `
        Send us an email at <a class="link" href="mailto:nicolas@anagraph.io?Subject=Hello%20Anagraph">nicolas@anagraph.io</a> 
        ou Subscribe to our newsletter`,
        email_placeholder: "enter your email"
      },
      about: {
        head_1: "Geographic intelligence for decision-makers",
        text_1: `
        Location  intelligence is an innovative method, 
        at the crossroads of BI (Business Intelligence) and GIS (Geographic Information System). 
        More than visualizing the information on a map, 
        LI makes it possible to use the data that has a geographic reference to bring out results that can be exploited in the business community by highlighting neighborhood relations that are invisible in Excel spreadsheets.
        `,
        head_2: "Geometric, powered by Anagraph",
        text_2: `
        There are hundreds of software and solutions to find and collect data.
        It is by noting that few decision makers were able to visualize them and give them meaning that we developed Geometric. 
        A tool that is part of Anagraph's DNA, namely the facilitation of decision-making thanks to a universal and intinctive graduation: the map.
          Smart maps to enlighten leaders and support innovation in small and large Canadian businesses.
        `,
        head_3: "Anagraph, innovation studio",
        text_3: `
        Based in the Laurentians, our company is made up of creative minds ready to imagine the future of geospatial information visualization.
        Led by Nicolas, founder with proven experience in spatial information technology and geolocation services, now president of the Canadian Association of Geomatics Sciences, Montreal branch.
        And by his side, Jean-Denis, Thomas, Marc-Andre, Mathew, Clement and Alexis, developers and programmers emeritus specialized in the creation of intuitive solutions`,
        head_4: "The field of possibilities in terms of location intelligence",
        text_4: `
        Our recent collaborations with both public and private sector actors demonstrate the power of location intelligence applied to diverse and diverse fields. <br>
        - If you are curious about <a class="link" href="http://www.anagraph.io">our current projects and their scope </a> <br>
        - If you think we can <a class="link" href="#contact">help you achieve the impossible. </a>
        `
      },
      footer: {
        by: "by"
      }
    }
  }
};
export default messages;
