<template>
  <section id="intro" class="wrapper">
    <div class="card-1 card">
      <h1 class="h1-big">{{ $t("message.intro.head_1") }}</h1>
      <p v-html="$t('message.intro.text_1')"></p>
      <a :href="geometricLink">
        <button class="red">{{ $t("message.intro.cta_1") }}</button>
      </a>
    </div>
    <div id="solutions" class="card-2 card">
      <h1 class="h1-small">{{ $t("message.intro.head_2") }}</h1>
      <p v-html="$t('message.intro.text_2')"></p>
      <button class="black">
        <a :href="geometricLink">{{ $t("message.intro.cta_2") }}</a>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "intro",
  data() {
    return {
      geometricLink: process.env.VUE_APP_GEOMETRIC_LINK
    };
  },
  methods: {
    scrollTo(route) {
      this.$router.push({ path: route });
    }
  }
};
</script>

<style lang="scss">
#intro .card {
  text-align: left;
}
@media (min-width: 320px) {
  #intro {
    background-image: url("../../assets/images/title-1-background-small.png");
    background-repeat: no-repeat;
    background-position: 53px 0px;
    padding-top: 84px;
    .card {
      padding: 40px 0 40px 20px;
      grid-column: col-start 1 / span 4;
    }
  }
}
@media (min-width: 768px) {
  #intro {
    background-position: 240px 53px;
    .card {
      padding-left: 20px;
      grid-column: col-start 3 / span 4;
    }
  }
}
@media (min-width: 1024px) {
  #intro {
    background-image: url("../../assets/images/title-1-background.png");
    background-position: 57px 82px;
    padding-top: 367px;
    .card {
      height: 890px;
      padding-left: 30px;
      padding-right: 16px;
      grid-column: col-start 1 / span 6;
    }
    .card-2 {
      grid-column: col-start 1 / span 5;
    }
  }
  @media (min-width: 1440px) {
    #intro {
      background-position: 407px 82px, 575px 1200px;
    }
  }
}
</style>
