<template>
  <div id="pricing" class="background-dark">
    <div class="wrapper">
      <h1 class="h1-small pricing-title">{{ $t("message.pricing.title") }}</h1>
      <div id="pricing-description">
        <p>{{ $t("message.pricing.description") }}</p>
      </div>
      <div class="price-card card-1">
        <h6>{{ $t("message.pricing.public.category") }}</h6>
        <h2>{{ $t("message.pricing.public.title") }}</h2>
        <div
          class="pricing-category-description"
          v-html="$t('message.pricing.public.description')"
        ></div>
        <a :href="geometricLink">
          <button>{{ $t("message.pricing.public.button") }}</button>
        </a>
      </div>
      <div class="price-card card-2">
        <h6>{{ $t("message.pricing.basic.category") }}</h6>
        <h2>{{ $t("message.pricing.basic.title") }}</h2>
        <div
          class="pricing-category-description"
          v-html="$t('message.pricing.basic.description')"
        ></div>
        <a :href="geometricLink">
          <button>{{ $t("message.pricing.basic.button") }}</button>
        </a>
      </div>
      <div class="price-card card-3">
        <h6>{{ $t("message.pricing.advanced.category") }}</h6>
        <h2>{{ $t("message.pricing.advanced.title") }}</h2>
        <div
          class="pricing-category-description"
          v-html="$t('message.pricing.advanced.description')"
        ></div>
        <a :href="geometricLink">
          <button>{{ $t("message.pricing.advanced.button") }}</button>
        </a>
      </div>
      <div class="price-custom">
        <h6>{{ $t("message.pricing.custom.category") }}</h6>
        <div
          class="pricing-category-description"
          v-html="$t('message.pricing.custom.description')"
        ></div>
        <button
          class="price-custom-contact-button"
          v-html="$t('message.pricing.custom.button')">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
  data() {
    return {
      geometricLink: process.env.VUE_APP_GEOMETRIC_LINK
    };
  }
};
</script>

<style lang="scss">
#pricing {
  h6 {
    color: #f4f2f8;
  }
  color: #c2c2c8;
  h2 {
    color: #c2c2c8;
    font-size: 2rem;
    line-height: 3.5rem;
  }
  padding-top: 220px;
  text-align: center;
  .pricing-title {
    color: white;
    grid-column: 1 / span 12;
    grid-row: 1;
  }
  #pricing-description {
    color: #c2c2c8;
    padding: 14px 20px;
    grid-column: 5 / span 4;
    grid-row: 2;
    padding-bottom: 53px;
  }
  .price-card {
    padding: 30px 16px;
    background-color: rgba(42, 42, 53, 0.5);
    border-radius: 7px;
    box-shadow: 0px 2px 4px 0px #20202c;

    .pricing-category-description {
      padding: 20px 12px;
      text-align: center;
      color: #c0c0c8;
      height: 220px;
      overflow: hidden;
      margin-bottom: 20px;
      line-height: 1.5rem;
      div {
        padding-bottom: 12px;
      }
    }
  }
  .price-custom {
    padding: 32px;
    text-align: left;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    h6 {
      padding-bottom: 24px
    }
    div {
      padding-bottom: 24px
    }
    button {
      width: 124px;
    }
  }
  @media (min-width: 320px) {
    .card-1 {
      grid-column: 1 / span 12;
      grid-row: 3;
    }
    .card-2 {
      grid-column: 1 / span 12;
      grid-row: 4;
    }
    .card-3 {
      grid-column: 1 / span 12;
      grid-row: 5;
    }
    .price-custom {
      grid-column: 1 / span 12;
      grid-row: 6;
    }
  }
  @media (min-width: 768px) {
    .card-1 {
      grid-column: 4 / span 4;
      grid-row: 3;
    }
    .card-2 {
      grid-column: 4 / span 4;
      grid-row: 4;
    }
    .card-3 {
      grid-column: 4 / span 4;
      grid-row: 5;
    }
    .price-custom {
      grid-column: 4 / span 4;
      grid-row: 6;
    }
  }
  @media (min-width: 1024px) {
    .card-1 {
      grid-column: 1 / span 4;
      grid-row: 3;
    }
    .card-2 {
      grid-column: 5 / span 4;
      grid-row: 3;
    }
    .card-3 {
      grid-column: 9 / span 4;
      grid-row: 3;
    }
    .price-custom {
      grid-column: 1 / span 12;
      grid-row: 4;
    }
  }
}
</style>
