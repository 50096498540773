<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-header">
          <div class="modal-lang"><locale-changer /></div>
          <div class="modal-close" @click="$emit('close');">
            <img
              src="../assets/images/Icon/24x24/close.png"
              alt=""
              @click="showMobileMenu = true;"
            />
          </div>
        </div>
        <div class="modal-body">
          <nav class="mobile" @click="$emit('close');">
            <div class="modal-links">
              <router-link :to="{ path: `/${$i18n.locale}/#solutions` }">{{
                $t("message.header.solutions")
              }}</router-link>
              <router-link :to="{ path: `/${$i18n.locale}/#pricing` }">{{
                $t("message.header.pricing")
              }}</router-link>
              <router-link :to="{ path: `/${$i18n.locale}/about` }">{{
                $t("message.header.about")
              }}</router-link>
              <router-link :to="{ path: `/${$i18n.locale}/#contact` }">
                Contact
              </router-link>
            </div>
            <button class="black modal-button" @click="$emit('close');">
              {{ $t("message.header.explore") }}
            </button>
          </nav>
        </div>
        <!--
          <div class="modal-footer">
            Follow us
          </div>
        -->
      </div>
    </div>
  </transition>
</template>

<script>
import LocaleChanger from "./LocaleChanger.vue";
export default {
  name: "mobileMenu",
  components: {
    LocaleChanger
  }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff5a50;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-button {
  background-color: #ff5a50;
}

.modal-wrapper {
  padding: 28px 32px;
  margin: 0 auto;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  font-family: "geomanist-bold";
  font-size: 14px;
}

.modal-body {
  .modal-links {
    padding: 52px 0;
    display: flex;
    flex-direction: column;
    font-family: "geomanist-bold";
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.07;
    letter-spacing: normal;
    text-align: center;
    color: #20202c;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
