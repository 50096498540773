<template>
  <header id="header" class="background-light">
    <mobile-menu v-if="showMobileMenu" @close="showMobileMenu = false;">
    </mobile-menu>
    <div id="header-content">
      <div id="nav-left">
        <router-link to="/"
          ><img src="../assets/logo-light.svg" class="geometric"
        /></router-link>
      </div>
      <nav class="desktop">
        <ul>
          <router-link :to="{ path: `/${$i18n.locale}/#solutions` }">
            {{ $t("message.header.solutions") }}
          </router-link>
          <router-link :to="{ path: `/${$i18n.locale}/#pricing` }">{{
            $t("message.header.pricing")
          }}</router-link>
          <router-link :to="{ path: `/${$i18n.locale}/about` }">{{
            $t("message.header.about")
          }}</router-link>
          <router-link :to="{ path: `/${$i18n.locale}/#contact` }">
            Contact
          </router-link>
        </ul>
      </nav>
      <div class="nav-right desktop">
        <locale-changer />
        <a :href="geometricLink"
          ><button class="small red">
            {{ $t("message.header.explore") }}
          </button></a
        >
      </div>
      <div class="nav-right mobile">
        <img
          src="../assets/images/Icon/24x24/burger.png"
          alt=""
          @click="showMobileMenu = true;"
        />
      </div>
    </div>
  </header>
</template>

<script>
import MobileMenu from "./MobileMenu.vue";
import LocaleChanger from "./LocaleChanger.vue";
export default {
  name: "v-header",
  components: {
    MobileMenu,
    LocaleChanger
  },
  data() {
    return {
      showMobileMenu: false,
      geometricLink: process.env.VUE_APP_GEOMETRIC_LINK
    };
  }
};
</script>

<style lang="scss">
.bm-menu {
  position: inherit;
}
#header {
  width: 100%;
  top: 0px;
  color: #20202c;
  @media (max-width: 1024px) {
    padding: 28px 32px;
    #header-content {
      nav.desktop {
        display: none;
      }
      .nav-right.desktop {
        display: none;
      }
    }
  }
  @media (min-width: 1024px) {
    padding: 52px 57px;
    #header-content {
      width: 910px;
      .nav-right.mobile {
        display: none;
      }
    }
  }
  @media (min-width: 1440px) {
    padding: 52px 57px;
    #header-content {
      width: 1326px;
    }
  }

  #header-content {
    justify-content: space-between;
    margin: 0 auto;
    font-family: "geomanist-medium";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #1e1c2c;
    display: flex;
    #nav-left {
      .geometric {
        width: 168px;
        height: 40px;
        object-fit: contain;
      }
    }
    nav {
      padding-top: 11px;
      justify-content: center;
      ul {
        margin: 0;
        a {
          padding: 0rem 1.75rem;
        }
      }
    }
    .nav-right.desktop {
      * {
        margin-right: 16px;
        margin-left: 16px;
      }
    }
  }
}
</style>
