<template>
  <div class="background-light home">
    <intro /> <actions /> <pricing />
    <!--
      <div id="partner" class="background-dark">
          #partner: to do
      </div>
    -->
    <contact />
  </div>
</template>

<script>
import Intro from "@/components/Home/Intro.vue";
import Actions from "@/components/Home/Actions.vue";
import Pricing from "@/components/Home/Pricing.vue";
import Contact from "@/components/Home/Contact.vue";

export default {
  name: "home",
  components: {
    Intro,
    Actions,
    Pricing,
    Contact
  },
  props: ["lang"],
  mounted() {
    if (this.lang) {
      this.$i18n.locale = this.lang;
    }
  }
};
</script>
<style lang="scss">
.home {
  @media (min-width: 1024px) {
    background-image: url("../assets/images/title-2-background.png");
    background-repeat: no-repeat;
    background-position: right -280px top 1200px;
  }
  @media (min-width: 1440px) {
    background-image: url("../assets/images/title-2-background.png"),
      url("../assets/images/pattern0.png"), url("../assets/images/pattern1.png"),
      url("../assets/images/pattern2.png"), url("../assets/images/pattern3.png");
    background-repeat: no-repeat;
    background-position: right 1200px, left 0px top 407px, left 0px top 970px,
      left 0px top 1700px, right 3000px;
  }
}
#partner {
  height: 220px;
}
</style>
