export const getUserLanguage = () => {
  const storedLang = window.localStorage.getItem("lang");
  if (storedLang) {
    return storedLang;
  } else {
    const browserLang = navigator.language || navigator.userLanguage;
    if (browserLang.substring(0, 2) === "fr") {
      return "fr";
    } else return "en";
  }
};
