<template>
  <div id="contact" class="background-dark">
    <div class="wrapper">
      <div id="contact-header">
        <h1 class="contact-title">{{ $t("message.contact.title") }}</h1>
        <p v-html="$t('message.contact.text')"></p>
      </div>
      <div id="contact-form">
        <form
          id="email-input-wrapper"
          action="https://eepurl.com/dPyEav"
          method="post"
          name="mc-embedded-subscribe-form"
          target="_blank"
          novalidate
        >
          <input
            id="email-input"
            type="email"
            name="EMAIL"
            :placeholder="$t('message.contact.email_placeholder')"
            required
          />
          <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input
              type="text"
              name="b_fe6ec1bc5cb0f149502b7ec51_f0402d324c"
              tabindex="-1"
              value=""
            />
          </div>
          <input
            type="image"
            id="email-validate"
            value="Subscribe"
            src="./StrokeRight.png"
            alt=""
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
};
</script>

<style lang="scss">
@media (min-width: 1440px) {
  #contact {
    background-image: url("../../assets/images/pattern2.png"),
      url("../../assets/images/pattern3.png");
    background-repeat: no-repeat;
    background-position: left 0px top 460px, right 250px;
  }
}
@media (max-width: 1024px) {
  #contact {
    display: none;
  }
}
#contact {
  color: #c2c2c8;
  padding-top: 250px;
  padding-bottom: 170px;
}
#contact-header {
  grid-column: col-start 2 / span 10;
  text-align: center;
  .contact-title {
    font-family: "geomanist-ultra";
    font-size: 175px;
    line-height: 0.82;
    letter-spacing: normal;
    text-align: center;
    color: #ff5b50;
    text-transform: uppercase;
  }
}
#contact-form {
  grid-column: col-start 5 / span 4;
  #email-input-wrapper {
    height: 58px;
    border-radius: 29px;
    margin-top: 42px;
    background-color: rgba(42, 42, 53, 0.5);
    border: none;
    text-align: left;
    #email-input {
      padding-left: 23px;
      height: 100%;
      width: 280px;
      color: white;
      background-color: rgba(42, 42, 53, 0);
      border: none;
    }
    #email-validate {
      padding-left: 23px;
      display: inline-block;
      vertical-align: middle;
      background-color: rgba(42, 42, 53, 0);
    }
  }
  #email-input::placeholder {
    padding-left: 1rem;
  }
}
</style>
