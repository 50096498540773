<template>
  <section id="actions" class="wrapper">
    <div class="card-3 card-small">
      <div class="card-small-image">
        <img src="@/assets/images/Icon/96x96/cellphone.svg" class="Color" />
      </div>
      <h6>{{ $t("message.goals.head_1") }}</h6>
      <p v-html="$t('message.goals.text_1')"></p>
    </div>
    <div class="card-4 card-small">
      <div class="card-small-image">
        <img src="@/assets/images/Icon/96x96/map.svg" class="Color" />
      </div>
      <h6>{{ $t("message.goals.head_2") }}</h6>
      <p v-html="$t('message.goals.text_2')"></p>
    </div>
    <div class="card-5 card-small">
      <div class="card-small-image">
        <img src="@/assets/images/Icon/96x96/report.svg" class="Color" />
      </div>
      <h6>{{ $t("message.goals.head_3") }}</h6>
      <p v-html="$t('message.goals.text_3')"></p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
#actions {
  display: grid;
  @media (min-width: 320px) {
    padding-bottom: 50px;
    .card-small {
      grid-column: col-start 1 / span 4;
      h6 {
        padding: 0 20px;
        margin: 0;
      }
      .card-small-image {
        margin: 30px auto;
      }
      p {
        padding: 16px 36px;
      }
    }
  }
  @media (min-width: 768px) {
    .card-small {
      grid-column: col-start 3 / span 4;
      h6 {
        padding: 0 20px;
        margin: 0;
      }
      .card-small-image {
        margin: 30px auto;
      }
      p {
        padding: 16px 36px;
      }
    }
  }
  @media (min-width: 1024px) {
    padding-bottom: 173px;
    .card-small {
      h6 {
        padding: 0 20px;
        margin: 0;
      }
      .card-small-image {
        margin: 30px auto;
      }
      p {
        padding: 16px 36px;
      }
    }
    .card-3 {
      grid-column: col-start 1 / span 4;
      grid-row: 1 / span 1;
    }
    .card-4 {
      grid-column: col-start 5 / span 4;
      grid-row: 1 / span 1;
    }
    .card-5 {
      grid-column: col-start 9 / span 4;
      grid-row: 1 / span 1;
    }
  }
  @media (min-width: 1440px) {
    .card-small {
      h6 {
        padding: 0 60px;
        margin: 0;
      }
    }
  }
}
</style>
