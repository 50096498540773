<template>
  <span id="lang">
    <a
      @click="recordLang($t('message.header.languageSwitch.path'));"
      :href="`/${$t('message.header.languageSwitch.path')}/`"
    >
      {{ $t("message.header.languageSwitch.name") }}
    </a>
  </span>
</template>

<script>
export default {
  name: "locale-changer",
  data() {
    return {
      activeItem: this.$i18n.locale
    };
  },
  methods: {
    recordLang: function(menuItem) {
      window.localStorage.setItem("lang", menuItem);
    }
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
