import Vue from "vue";
import injectInitialState from "./utils/injectInitialState";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import messages from "./locales/messages";
import "normalize.css";
import "./assets/geometric.scss";
Vue.use(VueI18n);

Vue.config.productionTip = false;
const i18n = new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages
});

// During pre-rendering the initial state is
// injected into the global scope, here we
// fill the store with the initial state.
if (window.__INITIAL_STATE__) store.replaceState(window.__INITIAL_STATE__);

router.beforeResolve(async (to, from, next) => {
  try {
    const components = router.getMatchedComponents(to);

    // By using `await` we make sure to wait
    // for the API request made by the `fetch()`
    // method to resolve before rendering the view.
    await Promise.all(components.map(x => x.fetch && x.fetch({ store })));

    // The `injectInitialState()` function injects
    // the current state as a global variable
    // `__INITIAL_STATE__` if the page is currently
    // pre-rendered.
    if (window.__PRERENDER_INJECTED) injectInitialState(store.state);
  } catch (error) {
    // This is the place for error handling in
    // case the API request fails for example.
    console.log(error);
  }

  return next();
});
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
